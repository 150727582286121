<template>
    <button
        class="select-button"
        :class="{ active, 'small-space': smallSpace }"
        @click="$emit('click', $event)"
    >
        <span class="select-button-content-wrapper">
            <Icon
                class="icon"
                :name="icon"
                :size="largeIcon ? 65 : 50"
                :color="active ? '#fff' : defaultColor"
            />
            <slot />
        </span>
    </button>
</template>

<script>
import Icon from '@/components/ui/Icon';
import colors from '@styles/abstracts/_variables.colors.scss';

export default {
    name: 'SelectButton',
    components: {
        Icon,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        largeIcon: {
            type: Boolean,
            default: false,
        },
        smallSpace: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            defaultColor: colors['first-primary-color'],
        };
    },
};
</script>

<style lang="scss" scoped>
.select-button {
    border: 2px solid $select-button-border-color;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 178px;
    padding: 12px;
    background-color: $select-button-background-color;
    font-size: 12px;
    font-weight: bold;
    color: $select-button-color;
    text-transform: uppercase;
    line-height: 1.3em;
    letter-spacing: 1.92px;
    text-align: center;
    transition: background-color 300ms;
    cursor: pointer;

    &:not(.active) {
        &:hover,
        &:focus {
            background-color: $select-button-border-color;
        }
    }

    &.active {
        border-color: $button-main-color;
        background-color: $button-main-color;
        color: $button-secondary-color;
    }
}

.select-button-content-wrapper {
    display: block;
}

.icon {
    margin: 0 auto 8px;

    .small-space & {
        margin-bottom: 0px;
    }
}
</style>
