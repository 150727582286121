<template>
    <div class="pdl-mode">
        <PageHeading :breadcrumbs="breadcrumbs" title="Select mode" />

        <div class="pdl-modes">
            <SelectButton
                class="m-r-2"
                icon="plus-2"
                large-icon
                small-space
                :active="mode === 'new'"
                @click="mode = 'new'"
            >
                Create new
            </SelectButton>

            <SelectButton
                icon="upload-2"
                large-icon
                small-space
                :active="mode === 'import'"
                @click="mode = 'import'"
            >
                Import from YouGov Direct
            </SelectButton>
        </div>

        <Stepper
            show-back-button
            show-next-button
            :nav-is-opened="navIsOpened"
            :next-button-is-disabled="!mode"
            @backButtonOnClick="onBackButtonClick"
            @nextButtonOnClick="onNextButtonClick"
        />
    </div>
</template>

<script>
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import SelectButton from '@/components/ui/SelectButton';
import PageHeading from '@/components/ui/PageHeading';
import Stepper from '@/components/ui/Stepper';
import { Routes } from '@/enums/routes.enum';

export default {
    name: 'PDLMode',
    components: {
        PageHeading,
        Stepper,
        SelectButton,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbs: [
                new Breadcrumb('PDL convos', { name: Routes.PdlList }),
                new Breadcrumb('New PDL convo'),
            ],
            mode: null,
        };
    },
    methods: {
        onBackButtonClick() {
            this.mode = null;
            this.$router.push({ name: Routes.PdlList });
        },
        async onNextButtonClick() {
            const name = this.mode === 'new' ? Routes.PdlCreate : Routes.PdlImport;
            this.$router.push({ name });
        },
    },
};
</script>

<style scoped lang="scss">
.pdl-mode {
    margin: 0 auto;
    max-width: 600px;
}

.pdl-modes {
    display: flex;
}
</style>
